import * as React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
    theme => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '0.5em',
            [theme.breakpoints.only('xs')]: {
                marginTop: 0,
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '1.5em',
            },
        },
    }),
    { name: 'RaMenu' }
);

const MyMenu = ({ resources, onMenuClick, logout, sidebarIsOpen, dense, ...rest }: any) =>{
    const classes = useStyles();
    return (
        <div className={classes.main}>
            {resources.map((resource: any) => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource?.options?.defaultPath || resource.name}`}
                    primaryText={resource?.options.label || resource.name}
                    leftIcon={<resource.icon />}
                    sidebarIsOpen={sidebarIsOpen}
                    onClick={onMenuClick}
                    dense={dense}
                />
            ))}
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));
