import { House as icon }  from "@material-ui/icons";
import list from "./List";

export default {
    name: "lead",
    list,
    icon,
    options: {
        label: "Leads",
    }
}